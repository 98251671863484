<!-- eslint-disable func-names -->
<template>
  <div>
    <footer class="footer-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <router-link to="/">
                <img
                  src="@/assets/images/logomameri.png"
                  alt="logo"
                  width="100px"
                >
              </router-link>
              <b-form>
                <b-form-group
                  class="text-white"
                  label="Newsletter"
                >
                  <b-form-input type="email" />
                </b-form-group>
              </b-form>

              <div class="footer-social">
                <a
                  href="#"
                  target="_blank"
                >
                  <i class="icofont-facebook" />
                </a>
                <a
                  href="#"
                  target="_blank"
                >
                  <i class="icofont-instagram" />
                </a>
                <a
                  href="#"
                  target="_blank"
                >
                  <i class="icofont-twitter" />
                </a>
                <a
                  href="#"
                  target="_blank"
                >
                  <i class="icofont-linkedin" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <h3>Nos services</h3>
              <ul>
                <li>
                  <a href="#">Annonce de fret</a>
                </li>
                <li>
                  <a href="#">Opportunités de transport</a>
                </li>
                <li>
                  <a href="#">Transport d'occasion</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <h3>Notre support</h3>
              <ul>
                <li>
                  <a href="#">Confidentialités</a>
                </li>
                <li>
                  <a href="#">FAQ</a>
                </li>
                <li>
                  <a href="#">Termes & Conditions</a>
                </li>
                <li>
                  <a href="#">Nous contacter</a>
                </li>
                <li>
                  <a href="#">A propos</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <h3>Liens rapides</h3>
              <ul>
                <li class="border-bottom">
                  <router-link to="/">
                    Accueil
                  </router-link>
                </li>
                <li class="border-bottom">
                  <router-link to="/affreteur">
                    Afrréteur
                  </router-link>
                </li>
                <li class="border-bottom">
                  <router-link to="transporteur">
                    Transporteur
                  </router-link>
                </li>
                <li>
                  <router-link to="contact">
                    Contact
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-area mt-1">
        {{ currentYear }} &copy; EBD Fret. Tous droits réservés.
      </div>
      <!-- <div class="lines">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div> -->
    </footer>
    <!-- Footer Area End -->
    <!-- Back top Button -->
    <div class="top-btn">
      <i class="icofont-scroll-bubble-up" />
    </div>
  </div>

</template>

<script>
import {
  BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import '@/assets/js/meanmenu'
import $ from 'jquery'

export default {
  components: {
    BForm, BFormGroup, BFormInput,
  },
  computed: {
    currentYear() {
      return (new Date()).getFullYear()
    },
  },
}
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// Pre Loader
$(window).on('load', () => {
  // $(".loader-content").fadeOut(1000);
  $('.mean-menu').meanmenu({
    meanScreenWidth: '991',
  })
  $('.top-btn').on('click', () => {
    $('html, body').animate({ scrollTop: 0 }, 1500)
    // alert('ok');
    return false
  })
})

// Navbar JS
// eslint-disable-next-line func-names
$(window).on('scroll', function () {
  if ($(this).scrollTop() > 150) {
    $('.navbar-area').addClass('is-sticky')
  } else {
    $('.navbar-area').removeClass('is-sticky')
  }
})
// eslint-disable-next-line func-names
$(window).scroll(function () {
  // eslint-disable-next-line eqeqeq
  if ($(this).scrollTop() != 0) {
    $('.top-btn').fadeIn()
  } else {
    $('.top-btn').fadeOut()
  }
})

// Back To Top
$(window).on('load', () => {
  $('.top-btn').fadeOut()
})
</script>
