<template>
  <!-- <div>
    <div class="navbar-area">
      <div class="mobile-nav">
        <a
          href="/"
          class="logo"
        >
          <img
            src="@/assets/images/logomameri.png"
            alt="logo"
          >
        </a>
      </div>
      <div class="main-nav">
        <div class="container">
          <nav
            class="navbar navbar-expand-lg navbar-light"
            style="margin-top:-20px;"
          >
            <router-link
              class="navbar-brand"
              to="/"
            >
              <img
                src="@/assets/images/logomameri.png"
                alt="logo"
                width=""
                height="70px"
              >
            </router-link>
            <div
              id="navbarSupportedContent"
              class="collapse navbar-collapse mean-menu"
            >
              <ul
                class="navbar-nav ml-auto justify-items-center"
                style="font-size: 15px;"
              >
                <li class="nav-item">
                  <router-link
                    to="/"
                    class="nav-link"
                  >
                    Accueil
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/affreteurs"
                    class="nav-link"
                  >
                    Affreteur
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/transporteurs"
                    class="nav-link"
                  >
                    Transporteur
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/documentheque"
                    class="nav-link"
                  >
                    Documenthèque
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link
                    to="/contact"
                    class="nav-link"
                  >
                    Contact
                  </router-link>
                </li>
                <li
                  v-if="!status"
                  class="nav-item ml-1"
                >
                  <router-link
                    to="/register"
                    class="nav-link"
                  >
                    <feather-icon
                      icon="UserPlusIcon"
                      size="16"
                      class="align-middle text-body"
                    /> S'inscrire
                  </router-link>
                </li>
                <li
                  v-if="!status"
                  class="nav-item"
                >
                  <router-link
                    to="/login"
                    class="nav-link"
                  >
                    <feather-icon
                      icon="LogInIcon"
                      size="16"
                      class="align-middle text-body"
                    /> Se Connecter
                  </router-link>
                </li>
                <li
                  v-if="status"
                  class="nav-item"
                >
                  <a
                    class="nav-link"
                    @click="redirect({ role: role })"
                  >
                    Mon espace
                  </a>
                </li>
                <li>
                  <router-link
                    v-if="!status"
                    class="btn btn-primary p-1 mx-1"
                    to="/expert-register"
                  >
                    Devenir expert
                  </router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div> -->
  <div class="navbar-area">
    <b-navbar
      toggleable="lg"
      type="light"
      variant="white"
      fixed
    >

      <b-navbar-brand href="/">
        <img
          src="@/assets/images/logomameri.png"
          alt="Kitten"
          style="width:100px;height:50px"
        >
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto navbar-nav ml-auto justify-items-center">
          <b-nav-item
            class="nav-link"
            to="/"
            active
          >
            {{ $t('navbar.home') }}
          </b-nav-item>
          <b-nav-item-dropdown
            :text="$t('navbar.about')"
            class="nav-link"
            right
          >
            <b-dropdown-item
              class="nav-link"
              to="/affreteur"
            >
              Affreteur
            </b-dropdown-item>
            <b-dropdown-item
              class="nav-link"
              to="/transporteur"
            >
              Transporteur
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item
            class="nav-link"
            to="/documentheque"
          >
            {{ $t('navbar.docu') }}
          </b-nav-item>
          <b-nav-item
            class="nav-link"
            to="/contact"
          >
            Contact
          </b-nav-item>
          <b-nav-item-dropdown
            v-if="status"
            :text="$t('navbar.user')"
            class="nav-link"
            right
          >
            <b-dropdown-item
              class="nav-link"
              @click="redirect({ role: role })"
            >
              Mon espace
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            v-else
            :text="$t('navbar.user')"
            class="nav-link"
            right
          >
            <b-dropdown-item
              class="nav-link"
              to="/login"
            >
              Connexion
            </b-dropdown-item>
            <b-dropdown-item
              class="nav-link"
              to="/register"
            >
              Inscription
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            text="Lang"
            class="nav-link"
            right
          >
            <b-dropdown-item
              class="nav-link"
              @click="select('en')"
            >
              EN
            </b-dropdown-item>
            <b-dropdown-item
              class="nav-link"
              @click="select('fr')"
            >
              FR
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <router-link
            size="sm"
            class="btn btn-primary"
            style="margin-top:6px"
            to="/expert-register"
          >
            {{ $t('navbar.cta') }}
          </router-link>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
  onMounted,
} from '@vue/composition-api'
import useTypeDocumentation from '@/services/admin/categorieDocumentationService'
import store from '@/store'
import useAuthUser from '@/services/authentification/userService'
import {
  BNavbarNav, BNavItemDropdown, BNavItem, BDropdownItem, BCollapse, BNavbarToggle, BNavbarBrand, BNavbar,
} from 'bootstrap-vue'

export default {
  components: {
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BCollapse,
    BNavbarToggle,
    BNavbarBrand,
    BNavbar,
    // Navbar Components
  },
  data() {
    return {
      role: '',
      status: false,
    }
  },
  mounted() {
    this.status = store.state.authStore.authenticated
    // eslint-disable-next-line no-unused-expressions
    JSON.parse(store.state.authStore.user) ? this.role = JSON.parse(store.state.authStore.user).role : this.role = ''
  },
  methods: {
    getStatus() {
      this.status = this.mystore
    },
    select(lang) {
      this.$i18n.locale = lang
    },
  },
  setup() {
    const {
      typeDocumentations, getTypeDocumentations, errors, Process,
    } = useTypeDocumentation()
    const {
      getAuthUser, getUserRole, redirectUser,
    } = useAuthUser()
    const mystore = false
    const role = ''
    onMounted(
      async () => {
        await getTypeDocumentations()
      },
    )
    const redirect = data => {
      redirectUser(data)
    }
    return {
      typeDocumentations,
      getTypeDocumentations,
      errors,
      getAuthUser,
      getUserRole,
      redirect,
      mystore,
      role,
      redirectUser,
      Process,
    }
  },
}
</script>
<style>
.nav-link{
  font-weight: 600;
}
.nav-link:hover{
  font-weight: 600;
  color: #FF5E13 !important;
}
.navbar-light .navbar-toggler {
    border-color: white !important;
    color: black !important;
}
</style>
